<template>
  <body class="auth-wrapper container">
    <div class="all-wrapper menu-side with-pattern">
      <div class="auth-form">
        <img alt="logo" class="logo" :src="logo" />
        <h3 class="heading">Reset Password</h3>
        <a-form
          layout="vertical"
          :model="form"
          :rules="validationRules()"
          name="reset-password"
          class="reset-password-form"
          id="reset-password-form"
          @finish="onSubmit"
        >
          <div class="row gx-4">
            <div class="col-sm-6">
              <a-form-item label="Password" name="password" autocomplete="off">
                <a-input-password
                  v-model:value="form.password"
                  placeholder="Password"
                  visibilityToggle="true"
                  @change="validPasswordPattern(form.password)"
                />
              </a-form-item>
              <div v-if="this.errors.length">
                <span style="color:red" v-for="error in errors" :key="error">
                  {{ error }}
                </span>
              </div>
            </div>

            <div class="col-sm-6">
              <a-form-item label="Confirm Password" name="confirmPassword">
                <a-input-password
                  v-model:value="form.confirmPassword"
                  placeholder="Confirm Password"
                  visibilityToggle="true"
                  type="password"
                />
              </a-form-item>
            </div>
          </div>

          <div class="buttons-w mt-2">
            <a-button
              type="primary"
              size="large"
              html-type="submit"
              class="btn-block"
            >
              <span>Submit</span>
            </a-button>
          </div>
        </a-form>
      </div>
    </div>
  </body>
</template>
<script>
import logo from '../../assets/img/assembly-cp-logo.png';
import { mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import { validUserName } from '../../utils/auth.js';
export default {
  mounted() {
    // this.clearLocalState();
  },
  setup() {
    return { logo, validUserName };
  },
  data() {
    return {
      token: useRoute().params.token,
      form: {
        password: '',
        confirmPassword: '',
      },
      errors: [],
      passwordErrorMessage: '',
    };
  },
  methods: {
    ...mapActions(['userSignup', 'changePassword', 'clearState']),
    clearLocalState() {
      this.clearState();
    },
    async validPassword(_, password) {
      if (password && password === this.form.password) {
        return Promise.resolve();
      } else {
        return Promise.reject('Confirm password must be equal to password');
      }
    },

    async validPasswordPattern(password) {
      this.errors = [];
      if (password.length < 9) {
        this.errors.push('Your password must be at least 9 characters. ');
      }
      if ((password.match(/[A-Z]/g) || []).length < 1) {
        this.errors.push(
          'Your password must contain at least one capital letter.'
        );
      }
      if ((password.match(/[0-9]/g) || []).length < 2) {
        this.errors.push('Your password must contain at least two numbers. ');
      }
      if ((password.match(/[^\w\s]/g) || []).length < 2) {
        this.errors.push(
          'Your password must contain at least two Special characters. '
        );
      }
      if (this.errors.length > 0) {
        return false;
      }
      return Promise.resolve();
    },

    validationRules() {
      return {
        password: [
          {
            required: true,
            min: 9,
            trigger: 'submit',
          },
        ],
        confirmPassword: [
          { required: true, validator: this.validPassword, trigger: 'submit' },
        ],
      };
    },
    onSubmit() {
      this.changePassword({
        token: this.token,
        password: this.form.password,
        confirm_password: this.form.confirmPassword,
      });
    },
  },
};
</script>
<style scoped>
.container {
  height: 100vh;
}

.auth-form {
  max-width: 510px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.reset-password-form {
  width: 90%;
  margin-top: 20px;
}
.center {
  display: flex;
  align-items: center;
}
.header-icon {
  font-size: 60px;
  color: #047bf8;
}
.pre-icon {
  font-size: 24px;
  color: #047bf8;
  margin-left: -12px;
}
.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
</style>
